@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=B612:wght@400;700&display=swap');

body {
  min-width: 320px;
}

a {
  color: #4fc3f7;
}

a:hover {
  color: #29b6f6;
}

a:active {
  color: #0288d1;
}

a:visited {
  color: #ce93d8;
}
